<template>
  <b-modal :modal-class="{'activeTour' : getGuidedTour.step5}" centered modal-class="save_brand_model"
           id="modal-create-brand-v2" hide-footer
           hide-header>
    <div class="w-full h-full">
      <div @click="$bvModal.hide('modal-create-brand-v2')" class="float-right w-6 h-6 flex justify-end">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
          </svg>
      </div>
      <div class="w-full px-10 flex flex-col items-center pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-[2.25rem] h-[2.063rem]" viewBox="0 0 36 33" fill="none">
          <path d="M34 27.7778C34 28.6324 33.6629 29.4519 33.0627 30.0562C32.4626 30.6605 31.6487 31 30.8 31H5.2C4.35131 31 3.53737 30.6605 2.93726 30.0562C2.33714 29.4519 2 28.6324 2 27.7778V5.22222C2 4.36764 2.33714 3.54805 2.93726 2.94377C3.53737 2.33948 4.35131 2 5.2 2H13.2L16.4 6.83333H30.8C31.6487 6.83333 32.4626 7.17282 33.0627 7.7771C33.6629 8.38138 34 9.20097 34 10.0556V27.7778Z" stroke="#2560D7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[1rem] font-bold leading-5">{{ computeModalTitle }}</p>
        <p class="pt-1 text">Please enter details to add new Workspace.</p>
        <div class="pt-8 w-full flex items-center">
          <div class="border-r border-r-[#F2F3F8] pr-2">
            <div style="width: 7.5rem !important; height: 7.5rem !important" class="profile_image" :style="brandImageLink(getBrandAdd.avatar)">

              <div class="input_file" v-if="!getBrandLoaders.image_upload">
                <input @change="uploadImageForBrand($event)" type="file" class="profile_input"
                       id="profile_input" data-cy="p-image">
                <label for="profile_input">
                  <i v-tooltip.top-center="'Change Image'" class="far fa-pencil"></i>
                </label>
              </div>
              <div class="input_file bg_opacity" v-else>
                <clip-loader :color="color"></clip-loader>
              </div>
            </div>
          </div>
          <div class="pl-2 flex-1">
            <FloatingLabelInput
              id="email"
              v-model="getBrandAdd.name"
              type="text"
              label="Workspace Name"
              customClass="h-[3.5rem] bg-[#F4F6FA]"
              :errorType="validations.name || validations.nameLength  ? 'danger' : ''"
            >
              <template v-slot:error_message>
                <InputFieldMessage v-if="validations.name" :message="messages.name"></InputFieldMessage>
                <InputFieldMessage v-else-if="validations.nameLength" :message="messages.nameLength"></InputFieldMessage>
              </template>
            </FloatingLabelInput>
            <URLInputFloatingV2
              class="pt-3"
              id="url"
              v-model="getBrandAdd.url"
              :validations="validations"
              type="text"
              label="Link"
              customClass="h-[3.5rem] bg-[#F4F6FA]"
              :errorType="validations.url || validations.urlValid || validations.urlLength  ? 'danger' : ''"
            >
              <template v-slot:error_message>
                <InputFieldMessage v-if="validations.url" :message="messages.url"></InputFieldMessage>
                <InputFieldMessage v-else-if="validations.urlLength" :message="messages.urlLength"></InputFieldMessage>
                <InputFieldMessage v-else-if="validations.urlValid" :message="messages.urlValid"></InputFieldMessage>
              </template>
            </URLInputFloatingV2>
          </div>
        </div>
        <div class="pt-10 pb-11 flex gap-x-3 justify-between items-center">
          <Button
            id="login-button"
            type="button"
            class="text-white bg-[#2560D7] hover:bg-blue-700"
            buttonClass="btn-lg"
            @click.prevent="handleStoreBrand()"
          >
            <template v-if="!getBrandLoaders.store" v-slot:label>{{ computeButtonLabel }}</template>
            <template v-if="getBrandLoaders.store" v-slot:loader>
              <Loader></Loader>
            </template>
          </Button>
          <Button
            id="login-button"
            type="button"
            class="border border-gray-500 hover:bg-[#F2F3F8]"
            buttonClass="btn-lg"
            @click="$bvModal.hide('modal-create-brand-v2')"
          >
            <template v-slot:label>Close</template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import {brandMessages, brandValidations, validLength} from '../../../common/attributes'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'
import { trackBrandCreated } from '@/common/methods'
export default {
  data() {
    return {
      validations: brandValidations,
      messages: brandMessages,
      validLength: validLength,
      color: DARK_PURPLE_LOADER_COLOR
    }
  },
  computed: {
    ...mapGetters(['getBrandAdd', 'getBrandLoaders', 'getProfile']),
    computeModalTitle () {
      return this.getBrandAdd._id ? 'Edit Workspace' : 'Add New Workspace'
    },
    computeButtonLabel () {
      return this.getBrandAdd._id ? 'Update' : 'Create'
    }
  },
  components: {
    FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
    URLInputFloatingV2: () => import('@/ui/ui-kit/v2/URLInputFloatingV2.vue'),
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
  },
  methods: {
    ...mapActions(['uploadImageForBrand', 'storeBrand']),
    async handleStoreBrand () {

      if (this.getBrandLoaders.image_upload) {
        this.$store.dispatch('toastNotification', {message: this.getUploadImageMessage, type: 'error'})
        return
      }

      this.validations.name = this.requiredCheck(this.getBrandAdd.name)
      this.validations.url = this.requiredCheck(this.getBrandAdd.url)
      this.getBrandAdd.url = this.urlConcatenation(this.getBrandAdd.url)
      this.validations.urlValid = !this.isValidURL(this.getBrandAdd.url)
      if (!this.validations.url) this.validations.url_valid = !this.isValidURL(this.getBrandAdd.url)

      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        if (await this.storeBrand()) {
          this.$bvModal.hide('modal-create-brand-v2')
          if (!this.getBrandAdd._id) {
            // notify with user-maven event
            await trackBrandCreated(this.getProfile)
          }
        }
      }
    }
  },
  watch: {
    'getBrandAdd.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
        this.validations.nameLength = !this.maxLength(value, 100)
      }
    }
  }
}
</script>
<style lang="less">
.save_brand_model {
  .modal-dialog {
    max-width: 36.5rem !important;
  }
  .modal-content {
    border-radius: 16px !important;
    border-color: #F2F3F8 !important;
    padding: 16px !important;
  }
  .modal-body {
    padding: 0 !important;
  }
}

</style>
